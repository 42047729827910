import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LineHistoryMaster } from '../AllModels';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import { getAllBidPointByLineId } from '../AllURL';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-get-all-bid-point-by-sort',
  templateUrl: './get-all-bid-point-by-sort.component.html',
  styleUrls: ['./get-all-bid-point-by-sort.component.css']
})
export class GetAllBidPointBySortComponent implements OnInit {
  LineHistoryMasterModel = new LineHistoryMaster();
  opentableFlag: boolean = true;

  displayedColumns: string[] = ['Sr.No.', 'value', 'lineName', 'session', 'game', 'totalBid', 'bidPoints'];
  displayedColumns1: string[] = ['Sr.No.', 'value', 'lineName', 'session', 'game', 'totalBid', 'bidPoints'];

  dataSource = new MatTableDataSource();
  dataSource1 = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  @ViewChild('epltable1', { static: false }) epltable1: ElementRef;
  getAllOpenLineData: any;
  getAllCloseLineData: any;

  constructor(private http: HttpmethodsService) { }

  ngOnInit() {
    if (sessionStorage.getItem("gameName") == "Jodi") {

      var length = 99;
      var jodiFlag = true;
      this.opentableFlag = false;
    }
    this.LineHistoryMasterModel.agentId = Number(sessionStorage.getItem("agentId"));
    this.LineHistoryMasterModel.lineId = Number(sessionStorage.getItem("lineId"));
    this.LineHistoryMasterModel.lineName = sessionStorage.getItem("lineName");
    this.LineHistoryMasterModel.gameName = sessionStorage.getItem("gameName");
    this.getAllOpenLine()
    this.getAllCloseLine()
  }

  getAllOpenLine() {
    let obj = {
      gameName: this.LineHistoryMasterModel.gameName,
      lineId: this.LineHistoryMasterModel.lineId,
      session: 'OPEN',
    }
    this.http.postRequest(getAllBidPointByLineId, obj).subscribe(
      (res: any) => {
        console.log({ getAllOpenLine: res });
        const initialValue = 0;
        this.getAllOpenLineData = res.reduce(
          (accumulator, currentValue) => accumulator + currentValue.bidPoints,
          initialValue,
        );

        this.dataSource = res;
      })
  }
  getAllCloseLine() {
    let obj = {
      gameName: this.LineHistoryMasterModel.gameName,
      lineId: this.LineHistoryMasterModel.lineId,
      session: 'CLOSE',
    }
    this.http.postRequest(getAllBidPointByLineId, obj).subscribe(
      (res: any) => {
        console.log({ getAllCloseLine: res });
        this.dataSource1 = res;

        const initialValue = 0;
        this.getAllCloseLineData = res.reduce(
          (accumulator, currentValue) => accumulator + currentValue.bidPoints,
          initialValue,
        );

      })
  }
  exportToExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'BidWiseLineHistoryOpen.xlsx');
  }

  exportToExcel1() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable1.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'BidWiseLineHistoryClose.xlsx');
  }
}
