export const MainURL = {
  // HostUrl: 'https://bigdadygames.com:6066',

  //local

  // HostUrl: 'http://97.74.91.204:2585',
  // HostUrl: 'http://192.168.0.225:8784',
  HostUrl: 'http://97.74.91.204:8784',

  // HostUrl: 'http://97.74.91.174:7080',
  // 
  // HostUrl: 'http://a5c2a5edf4e8.ngrok.io',
  imageUrl: '/assets'
}
