import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateAdapter, MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { LineWaise, UserMaster } from '../AllModels';
import { getActiveListURL, getLineWiseDataURL } from '../AllURL';
import { HttpmethodsService } from '../shared/service/httpmethods.service';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-linehistory',
  templateUrl: './linehistory.component.html',
  styleUrls: ['./linehistory.component.css']
})
export class LinehistoryComponent implements OnInit {
  linehistoryForm: FormGroup;
  ActiveLineList: any;
  LineWaiseModel = new LineWaise();
  roleFlag: boolean = false;
  roleId: number;
  constructor(private formBuilder: FormBuilder, public toastr: ToastrManager, private httpService: HttpmethodsService, private dateAdapter: DateAdapter<Date>, private router: Router) {
    this.dateAdapter.setLocale('en-GB');
  }

  displayedColumns: string[] = ['Sr.No.', 'Line Name', 'Line Date', 'Open No', 'Mid No', 'Close No', 'Status', 'Action'];
  dataSource = new MatTableDataSource<UserMaster>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  ngOnInit() {
    this.linehistoryForm = this.formBuilder.group({
    })
    this.getActiveLine();

    this.roleId = Number(sessionStorage.getItem("roleId"));

    if (this.roleId == 1) {
      this.roleFlag = true;

    }
    else {
      this.roleFlag = false;


    }
  }

  getActiveLine() {
    this.httpService.getRequest(getActiveListURL).subscribe((data: any) => {
      if (data) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
      }
      else {
        this.toastr.errorToastr("No Records Found.", "Error");
      }
    })
  }
  getLineWiseData(lineId) {
    this.httpService.getRequest(getLineWiseDataURL + "/" + lineId).subscribe((data: any) => {
      this.LineWaiseModel = data;
      if (this.LineWaiseModel.totalCountWinPoints == null) {
        this.LineWaiseModel.totalCountWinPoints = 0;
      }
      if (this.LineWaiseModel.totalCountBidpoint == null) {
        this.LineWaiseModel.totalCountBidpoint = 0;
      }

    })
  }
  getLineById(element) {

    sessionStorage.setItem("lineId", element.lineId);
    sessionStorage.setItem("lineName", element.lineName)
    this.router.navigateByUrl('/layout/viewlinehistory');
  }

  // For Client purpose
  getAllBidPointBySort(element) {
    sessionStorage.setItem("lineId", element.lineId);
    sessionStorage.setItem("lineName", element.lineName)
    this.router.navigateByUrl('/layout/viewlinehistory');
  }
  // For Client purpose

  getLineById1(element) {

    sessionStorage.setItem("linemaster", JSON.stringify(element));
    sessionStorage.setItem("lineId", element.lineId);
    sessionStorage.setItem("lineName", element.lineName)
    sessionStorage.setItem("lineDate", element.lineDate)

    this.router.navigateByUrl('/layout/resultentry');
  }
  getLineById2(element) {

    sessionStorage.setItem("linemaster", JSON.stringify(element));
    sessionStorage.setItem("lineId", element.lineId);
    sessionStorage.setItem("lineName", element.lineName)
    sessionStorage.setItem("lineDate", element.lineDate)

    this.router.navigateByUrl('/layout/revertresult');
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  exportToExcel() {
    const ws: xlsx.WorkSheet =
      xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Line History.xlsx');
  }
}

